import { Controller } from "@hotwired/stimulus"
import ValueParser from "../utils/value_parser"

// Connects to data-controller="calc"
export default class extends Controller {
  static targets = ['total', 'subtotal']

  static values = {
    baseData: { type: Array, default: [] }
  }

  static outlets = ['calc--row', 'dollar-field']

  connect() {
    app.calc = this
  }

  update(event) {
    this.total = this.grandTotal
    this.dollarFieldOutlets.forEach(d => d.blurFormat())
  }

  reset(event) {
    event.preventDefault()
    this.calcRowOutlets.forEach(r => r.reset())
    this.update()
  }

  totalChanged(event) {
    const newTotal = this.#parseNumber(this.totalTarget.value)

    this.calcRowOutlets.forEach(r => r.grandTotalUpdate(newTotal))
  }

  get priorTotal() {
    return this.baseDataValue.reduce((sum, row) => sum + this.#parseNumber(row.amount_sum), 0)
  }

  get totals() {
    return this.subtotalTargets.map(t => this.#parseNumber(t.value))
  }

  get grandTotal() {
    let sum = 0
    this.totals.filter(t => !isNaN(t)).forEach(t => sum += t)

    return sum
  }

  set total(val) {
    this.totalTarget.value = val
  }

  #parseNumber(str) {
    return ValueParser.castNumber(str.replace(/[,]/g, ""))
  }
}
