import { Controller } from "@hotwired/stimulus"
import ValueParser from "../../utils/value_parser"
import PercentService from "../../utils/percent_service"

// Connects to data-controller="calc--row"
export default class extends Controller {
  static targets = ['count', 'amount', 'total', 'change']

  static values = {
    baseData: { type: Object, default: {} }
  }

  static classes = ['disabledField', 'highlightedField']

  static outlets = ['dollar-field']

  connect() {
    app.calc_rows ??= []
    app.calc_rows.push(this)
  }

  giftUpdate(event) {
    this.updateTotalByGifts()
    this.updateChangeByTotal()
    this.highlightGiftFields()
    this.disable(this.changeTarget)
    this.announceChange()
    this.formatDollars()
  }

  changeUpdate(event) {
    this.updateTotalByChange()
    this.highlight(this.changeTarget)
    this.disableGiftFields()
    this.announceChange()
    this.formatDollars()
  }

  totalUpdate(event) {
    this.updateChangeByTotal()
    this.disableGiftFields()
    this.disable(this.changeTarget)
    this.announceChange()
    this.formatDollars()
  }

  disableGiftFields() {
    this.disable(this.countTarget)
    this.disable(this.amountTarget)
  }

  highlightGiftFields() {
    this.highlight(this.countTarget)
    this.highlight(this.amountTarget)
  }

  grandTotalUpdate(grandTotal) {
    const newTotal = grandTotal * this.#parsePercent(this.baseData.proportion) * 100

    this.totalTarget.value = newTotal.toFixed(2)
    this.formatDollars()
  }

  // disableChangeField() {
  //   this.disable(this.changeTarget)
  // }

  // highlightChangeField() {
  //   this.highlight(this.changeTarget)
  // }

  highlight(elem) {
    elem.classList.remove(...this.disableClasses)
    elem.classList.add(...this.highlightClasses)
  }

  disable(elem) {
    elem.classList.remove(...this.highlightClasses)
    elem.classList.add(...this.disableClasses)
  }

  undecorate(elem) {
    elem.classList.remove(...this.highlightClasses)
    elem.classList.remove(...this.disableClasses)
  }

  isGiftTarget(tgt) {
    return tgt === this.countTarget || tgt === this.amountTarget
  }

  isChangeTarget(tgt) {
    return tgt === this.changeTarget
  }

  isTotalTarget(tgt) {
    return tgt === this.totalTarget
  }

  updateTotalByGifts() {
    const count  = this.#parseCount(this.countTarget.value)
    const amount = this.#parseMoney(this.amountTarget.value)

    this.totalTarget.value = count * amount
  }

  updateTotalByChange() {
    let pct = this.#parsePercent(this.changeTarget.value)

    if (isNaN(pct)) { pct = 0 }

    let val = (pct + 1) * this.baseData.total

    if (val == 0) {
      // zero percent change from prior year
      val = this.baseData.total
    }

    this.totalTarget.value = val
  }

  updateChangeByTotal() {
    let current = this.total
    let prior = this.baseData.total
    // let change = ((current / prior))

    // ((float - (original / original.abs)) * 100).round(decimal)

    // this.changeTarget.value = PercentService.fraction(prior, current)
    this.changeTarget.value = PercentService.change(prior, current)
  }

  announceChange() {
    this.dispatch("changed")
  }

  formatDollars() {
    this.dollarFieldOutlets.forEach(d => d.blurFormat())
  }

  reset() {
    this.countTarget.value  = this.baseData.count
    this.amountTarget.value = this.baseData.avg
    this.totalTarget.value  = this.baseData.total
    this.formatDollars()
    this.updateChangeByTotal()

    this.undecorate(this.countTarget)
    this.undecorate(this.amountTarget)
    this.undecorate(this.changeTarget)
  }

  // Data

  get baseData() {
    return {
      avg: this.#parseMoney(this.baseDataValue.amount_avg),
      total: this.#parseMoney(this.baseDataValue.amount_sum),
      count: this.#parseCount(this.baseDataValue.gifts),
      change: this.#parsePercent(this.baseDataValue.yoy_change),
      proportion: this.#parsePercent(this.baseDataValue.proportion)
    }
  }

  get total() {
    return this.#parseMoney(this.totalTarget.value)
  }

  // Utils

  get disableClasses() {
    // return ['text-slate-400']
    return this.disabledFieldClasses
  }

  get highlightClasses() {
    // return ['text-sky-800', 'bg-sky-50']
    return this.highlightedFieldClasses
  }

  #parseCount(str) {
    return ValueParser.castNumber(str)
  }

  #parseMoney(str) {
    return ValueParser.parseCurrency(str)
  }

  #parsePercent(str) {
    return ValueParser.parsePercent(str)
  }
}
